<template>
  <div class="bg-catskill-white-500 h-screen font-roboto text-mine-shaft tracking-wide">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
